























































import Vue from 'vue'

export default Vue.extend({
  props: {
    id: String,
  },
  data() {
    return {
      loading: false,
      formLoading: false,
      rules: {
        url: [{ required: true, message: '请填写代理地址' }],
        status: [{ required: true, message: '请填写代理开启状态' }],
        usable: [{ required: true, message: '请填写代理是否可用' }],
        https: [{ required: true, message: '请填写代理是否使用https' }],
        remarks: [{ required: false, message: '请填写备注' }],
      },
      model: {
        url: '',
        status: '',
        usable: '',
        https: '',
        remarks: '',
      },
    }
  },
  created() {
    this.id && this.fetch()
  },
  methods: {
    async fetch() {
      this.formLoading = true
      const { data: configs } = await this.$http.get(`proxies/${this.id}`)
      this.model = Object.assign(this.model, configs)
      this.formLoading = false
    },
    async save() {
      ~(this.$refs.form as any).validate(async (valid: boolean) => {
        if (valid) {
          this.loading = true
          if (this.id) {
            await this.$http.put(`proxies/${this.id}`, this.model)
          } else {
            await this.$http.post('proxies', this.model)
          }
          this.loading = false
          this.$router.push('/proxy/list')
          this.$message.success('保存成功')
        }
      })
    },
  },
})
